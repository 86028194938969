// import axios for http fetching
import axios from 'axios';
// import calculated menu data
import lng from '../misc/lng';
import envConst from '../misc/envConst';
import { menuNameExtractHelper } from '../misc/envConst';

export const GET_PROD_LIST = 'GET_PROD_LIST';
export const UPDATE_PROD_STOCK_QTY = 'UPDATE_PROD_STOCK_QTY';
export const UPDATE_TOTAL_PAY_AMOUNT = 'UPDATE_TOTAL_PAY_AMOUNT';
export const GET_PROD_BY_ID = 'GET_PROD_BY_ID';
export const UPDATE_PROD_SHOP_LIST = 'UPDATE_PROD_SHOP_LIST';
export const ADD_BEVERAGE = 'ADD_BEVERAGE';
export const UPDATE_PROD_SHOP_QTY = 'UPDATE_PROD_SHOP_QTY';
export const UPDATE_PROD_QTY_TO_ZERO = 'UPDATE_PROD_QTY_TO_ZERO';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const SET_SYS_PARAM = 'SET_SYS_PARAM';
export const UPDATE_APPBAR_TITLE = 'UPDATE_APPBAR_TITLE';
export const ClEARORDER = 'ClEARORDER';
export const SAVE_LOCAL_WS_OBJ = "SAVE_LOCAL_WS_OBJ";
export const CHANGE_SHOP = 'CHANGE_SHOP';
export const REMOVE_BEVERAGE = 'REMOVE_BEVERAGE';

export const UPDATE_CART_WITH_SPEC_REMOVE = 'UPDATE_CART_WITH_SPEC_REMOVE';
export const UPDATE_CART_WITH_SPEC_DEL = 'UPDATE_CART_WITH_SPEC_DEL';
export const UPDATE_CART_WITH_SPEC_ADD = 'UPDATE_CART_WITH_SPEC_ADD';
export const UPDATE_CART_REMOVE = 'UPDATE_CART_REMOVE';
export const UPDATE_CART_DEL = 'UPDATE_CART_DEL';
export const UPDATE_CART_ADD = 'UPDATE_CART_ADD';

export const CLEAR_ALL_ADDITIONAL_OPTION_ORDER = 'CLEAR_ALL_ADDITIONAL_OPTION_ORDER';
export const UPDATE_ADDITIONAL_OPTION_ORDER = 'UPDATE_ADDITIONAL_OPTION_ORDER';
export const ADD_ADDITIONAL_OPTION_ORDER = 'ADD_ADDITIONAL_OPTION_ORDER';
export const UPDATE_ADDITIONAL_OPTION_CAT = 'UPDATE_ADDITIONAL_OPTION_CAT';
export const ADD_ADDITIONAL_OPTION_ORDER_TO_CART = 'ADD_ADDITIONAL_OPTION_ORDER_TO_CART';
export const UPDATE_ADDITIONAL_OPTION_ORDER_TO_CART = 'UPDATE_ADDITIONAL_OPTION_ORDER_TO_CART';

// retrieve all tables
export const GET_TBL_DATA = 'GET_TBL_DATA';
export const INIT_DINE_IN_PAGE = 'INIT_DINE_IN_PAGE';
export const INIT_FAIL = 'INIT_FAIL';

// store current table details in process
export const SET_TABLE_IN_CHARGE = 'SET_TABLE_IN_CHARGE'
export const setTableInCharge = (tblMetaData) => {
    return {
        type: SET_TABLE_IN_CHARGE,
        data: tblMetaData
    }
}
// retrieve table details of SET_TABLE_IN_CHARGE
export const GET_TABLE_IN_CHARGE = 'GET_TABLE_IN_CHARGE';
export const getTableInCharge = () => {
    return {
        type: GET_TABLE_IN_CHARGE
    }
}
export const CONFIRM_ORDER = 'CONFIRM_ORDER';
export const SET_FAIL_LOADING_WRAPPER = 'SET_FAIL_LOADING_WRAPPER';
export const SET_LOADING_WRAPPER = 'SET_LOADING_WRAPPER';
export const SET_MSG_DIALOG = 'SET_MSG_DIALOG';
export const SET_ADDS_SPEC_MEAL_DIALOG = 'SET_ADDS_SPEC_MEAL_DIALOG';
export const CEAR_CART = 'CEAR_CART';

// retrieve menu
export const INIT_MENU = 'INIT_MENU'
export const INIT_TAKEOUT_FAIL= 'INIT_TAKEOUT_FAIL';

export const changeShop = (shop) => {
    return {
        type: CHANGE_SHOP,
        data: shop,
    }
}

// Action for big menu
export const UPDATE_PRODUCT_ADD = 'UPDATE_PRODUCT_ADD';
export const UPDATE_PRODUCT_DEL = 'UPDATE_PRODUCT_DEL';
export const UPDATE_PRODUCT_WITH_SPEC_ADD = 'UPDATE_PRODUCT_WITH_SPEC_ADD';
export const UPDATE_PRODUCT_WITH_SPEC_DEL = 'UPDATE_PRODUCT_WITH_SPEC_DEL';

export const SET_ADD_MEAL_DIALOG = 'SET_ADD_MEAL_DIALOG';
export const UPDATE_CART_ADD_SEPARATELY = 'UPDATE_CART_ADD_SEPARATELY';
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const UPDATE_PRODUCT_QTY = 'UPDATE_PRODUCT_QTY';
export const PARSE_PRODUCT_DATA = 'PARSE_PRODUCT_DATA';

// Action for takeout
export const INIT_TAKEOUT_PAGE = 'INIT_TAKEOUT_PAGE';
export const SET_SHOPPING_CART = 'SET_SHOPPING_CART';

export const clearOrder = () => {
    return {
        type: ClEARORDER,
    }
}

export const addBeverage = (beverageList) => {
    return {
        type: ADD_BEVERAGE,
        data: beverageList ,
    }
}

export const removeBeverage = () => {
    return {
        type: REMOVE_BEVERAGE,
    }
}

export const updateAppbarTitle = (title=lng.chiLng.toolbarTitle) => {
    return {
        type: UPDATE_APPBAR_TITLE,
        title,
    }
}

export const changeView = (view, generatedOrderId, orderTimeStamp) => {
    return {
        type: CHANGE_VIEW,
        data: view,
        generatedOrderId,
        orderTimeStamp
    }
}

export const setSysParam = (isShopOpen, closeShopDescription) => {
    return {
        type: SET_SYS_PARAM,
        isShopOpen,
        closeShopDescription,
    }
}

export const saveWsObj = (wsObj) => {
    return {
        type: SAVE_LOCAL_WS_OBJ,
        ws: wsObj,
    };
}

// Retrieve plain menu json menu file data
export const getMenuJson = (shopID) => {
    // get named day for choosing today's menu
    const dayName = menuNameExtractHelper();
    let productGetUrl = `${envConst.GET_MENU_URL}/${dayName}-menu.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
    if (shopID === 'sh02') {
        productGetUrl = `${envConst.GET_MENU_URL}/teaTime.json?bust=${Date.now() /* bust url suffix for cache prevent */}`;
    }
    return axios.get(productGetUrl);
};

export const getProductList = (shopID = 'sh01', ) => {
    return (dispatch) => {
        // should replaced with fetch in the future
        // sh01 is hardcoded, need to handle when there are more than 1 resturants
        // const someProductData = productData.sh01;
        getMenuJson(shopID).then((res) => {
            // Update product list loaded from server
            dispatch({
                type: GET_PROD_LIST,
                productData: res.data[shopID]
            });
        });
    }
}

export const updateProductShopList = (productId, userActionType, productData) => {
    return {
        type: UPDATE_PROD_SHOP_LIST,
        productId,
        userActionType,
        productData
    }
}

export const updateProductQtyToZero = (productId, productType) => {
    return {
        type: UPDATE_PROD_QTY_TO_ZERO,
        productId,
        productType,
    }
}

// order url hash suffix is generated by hash of order id concatenate with order id
export const confirmOrderStatus = (
    orderStatus,
    orderAddress = null,
    orderClientContactName = null,
    orderClientContactNo = null,
    orderDeliveryTime = null,
    orderMoment = null,
    payAmount = 0,
) => {
    return async(dispatch, getState) => {
        // order id is designed as A0001
        // get the order count and generate new order ID from db
        // const targetUrl = `${envConst.API_REST_ORDER_COUNT_URL}`;
        return new Promise(
            function (resolve, reject) {
                const {productsReducer} = getState();
                const productReducerObj = productsReducer;
                const orderTimeStamp = typeof orderMoment !== undefined ? orderMoment.format() : null;
                // const hashOfOrderTimeStamp = sha256(orderTimeStamp);
                // const orderUrlHash = sha256(`${generatedOrderId}${hashOfOrderTimeStamp}`);
                const orderUrlHash = '';
            
                dispatch({
                    type: UPDATE_ORDER_STATUS,
                    generatedOrderId: '',
                    orderTimeStamp,
                    orderStatus,
                    productReducerObj,
                    orderUrlHash,
                    orderAddress,
                    orderClientContactNo,
                    orderClientContactName,
                    orderDeliveryTime,
                    payAmount,
                });
                resolve(
                    {
                        generatedOrderId: 'A0001',
                        orderTimeStamp,
                        orderStatus,
                        productReducerObj,
                        orderUrlHash,
                        orderAddress,
                        orderClientContactNo,
                        orderClientContactName,
                        orderDeliveryTime,
                        payAmount,
                    }
                );
            }
        );
    };
}