import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import SimpleOptionList from './menu/SimpleOptionList';
import IconButton from '@material-ui/core/IconButton';
import {setAddMealDialog, addCartItemSeparately} from '../../actions/dineIn';
import CloseIcon from '@material-ui/icons/HighlightOffOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import lng from '../../misc/lng';
import envConst from '../../misc/envConst';

const {chiLng} = lng;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative', // This makes the AppBar appear on top of the Dialog content
        backgroundColor: '#666666',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1, // This makes the title take up the available space not used by the buttons
    },
    root: {

    },
    img: {
        width: '100%',
    },
    content: {
        marginBottom: '150px',
        backgroundColor: '#f1efef'
    },
    // title: {
    //     marginTop: '10px',
    //     marginLeft: '10px'
    // },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px'
    },
    // price: {
    //     marginRight: '20px',
    //     marginTop: '10px',
    //     color: '#d2691e',
    //     fontWeight: '600',
    // },
    price: {
        fontWeight: '600',
        color: '#FFFFFF',
      },
    footer: {
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: '130px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 0 5px rgba(0, 0, 0, 0.8)',
    },
    qtyBtnWrapper: {
        margin: '10px auto',
        display: 'flex',
        alignItems: 'center',
    },
    qty: {
        color: '#d2691e',
        fontWeight: '600',
        fontSize: '30px',
        marginLeft: '10px',
        marginRight: '10px'
    },
    addOrderBtn: {
        width: '70%',
        height: '40px',
        color: 'white',
        backgroundColor: '#666666',
        borderRadius: '10px',
        fontSize: '30px',
        lineHeight: '40px',
    },
    btnBack: {
        position: 'absolute',
        top: '10px',
        right: '15px',
        color: 'white',
    },
  }));


export default (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        addMealDialog: {
            open, data
        }
    } = useSelector((store) => store.dineInReducer);

    
    const {
        menu: {simpleOptionList}
    } = useSelector((store) => store.menuReducer);
    
    const [qty, setQty] = useState(1);
    const [simpleOptList, setSimpleOptList] = useState([]);
    const [img, setImg] = useState(envConst.DEFAULT_IMG_PATH);

    const handleClickSimpleOpt = (opt) => {
        setSimpleOptList(
            simpleOptList.map(
                (sOpt) => {
                    if (opt.id === sOpt.id) {
                        return {
                            ...sOpt,
                            selected: !sOpt.selected,
                        };
                    }
                    return sOpt;
                }
            )
        );
    }

    const handleQtyAdd = () => {
        setQty(qty + 1);
    }

    const handleQtyReduce = () => {
        if (qty <= 1) return;
        setQty(qty - 1);
    }

    const handleClose = () =>{
        dispatch(setAddMealDialog(false, {}));
    }
    const imgSrc  = () => {
        // return `./assets/meal${props.meal.img}`;
        return envConst.DEFAULT_IMG_PATH;
    }

    const handleConfirm = () => {
        console.log('handleConfirm');
        const mealToAdd = {
            ...data,
            qty,
            price: data.price,
            simpleOptions: simpleOptList.filter(
                so => so.selected
            ).map(
                so => so.optionName
            ),
        };
        console.log(mealToAdd);
        dispatch(addCartItemSeparately(mealToAdd));
        handleClose();
    }

    // onEnter
    const onEnter = () => {
        console.log('onEnter ------');
        console.log(data)
        // if (data.imgFile) {
        //     setImg(`data:image/jpeg;base64, ${data.imgFile}`);
        // } else {
        //     setImg(envConst.DEFAULT_IMG_PATH);
        // }

        if (data && data.img) {
            setImg(`${envConst.IMAGE_PATH}${data.img}`);
        } else {
            setImg(envConst.DEFAULT_IMG_PATH);
        }
        
        setSimpleOptList(
            simpleOptionList.map(
                (opt) => {
                    return {
                        ...opt,
                        selected: false,
                    };
                }
            )
        );
        setQty(1);
    };

    const handleImgError = () => {
        console.log('handleImgError')
        setImg(envConst.DEFAULT_IMG_PATH);
    };

    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            onClose={handleClose}
            fullScreen
            TransitionProps={{onEnter: onEnter}}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="back">
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {data.name}
                    </Typography>
                    <Typography variant="h6" className={classes.price}>
                        ${data.price}
                    </Typography>
                </Toolbar>
            </AppBar>
            {/* <IconButton aria-label="back" className={classes.btnBack} onClick={handleClose}>
                <CloseIcon fontSize="large" />
            </IconButton> */}
            <div className={classes.content}>
                <></>
                <CardMedia
                    component="img"
                    alt={data.name}
                    image={img}
                    className={classes.img}
                    onError={handleImgError}
                />
                {/* <div className={classes.titleWrapper}>
                    <Typography className={classes.title} variant='h4'>
                        {data.name}
                    </Typography>
                    <Typography className={classes.price} variant='h4'>
                        ${data.price}
                    </Typography>
                </div> */}
                <Divider variant="middle" />
                <SimpleOptionList 
                    simpleOptionList={simpleOptList}
                    handleClickSimpleOpt={handleClickSimpleOpt}
                />
            </div>
            <div className={classes.footer}>
                <div className={classes.qtyBtnWrapper}>
                    <div className={classes.qtyAdd}>
                        <IconButton aria-label="add" onClick={handleQtyAdd}>
                            <AddCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                    </div>
                    <div className={classes.qty}>
                        {qty}
                    </div>
                    <div className={classes.qtyReduce} onClick={handleQtyReduce}>
                        <IconButton aria-label="reduce">
                            <RemoveCircleOutlineOutlinedIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
                <Typography align='center' className={classes.addOrderBtn} variant='h5' onClick={handleConfirm}>
                    {`${chiLng.addOrder} $${qty * data.price}`}
                </Typography>
            </div>
        </Dialog>
    );
};