import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import lng from '../../misc/lng';

const {chiLng} = lng;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', // Centers children vertically in the container
        alignItems: 'center', // Centers children horizontally in the container
        height: '100vh',
        overflow: 'hidden',
    },
    topPanel: {
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        color: 'white',
        backgroundColor: '#666666',
        height: '65px',
        width: '100%', // Full width
        position: 'fixed', // Fixed position to keep it at the top
        top: 0, // Stick to the top
        left: 0, // Stick to the left side
        zIndex: 1000, // Make sure it stacks on top of other elements if needed
    },
    title: {
        position: 'absolute', // Position absolutely within the parent flex container
        top: '50%', // 50% from the top of the parent container
        left: '50%', // 50% from the left of the parent container
        transform: 'translate(-50%, -50%)', // Adjust for the dimensions of the title itself
    },
    content: {
        width: '100%', // Full width
        textAlign: 'center', // Center text horizontally
    },
}));


export default (props) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.topPanel}>
                <Typography variant='h3'>
                    ALL DAY
                </Typography>
            </div>
            <div className={classes.root}>
                <Typography variant="h6" component="h2" className={classes.content}>
                    {props.title}
                </Typography>
            </div>
        </div>
    );
};